export const MAX_USERNAME_LENGTH = 35

export const MAX_ROLE_LENGTH = 16

export const MAX_CATEGORY_LENGTH = 255

export const DIALOG_TYPES = {
  public: 'Open 🌎',
  peekable: 'Peekable 👀',
  private: 'Private 🏖',
  friend: '1 on 1',
}

export const checkIs1on1Chat = (type: 'friend' | 'privateGroup' | 'peekableGroup' | 'publicGroup') => type === 'friend'

export const COLUMN_WIDTH = {
  QUICKBLOX_USER_ID: 70,
  USERNAME: 150,
  DATE_TIME: 155,
  MORE_DETAILS: 65,
  // FOR REPORTS
  ID: 50,
  LONG_QUICKBLOX_USER_ID: 130,
  CATEGORY: 200,
  DETAILS: 150,
}

export const EVENT = {
  // DETAILS
  SHOW_DETAILS: 'showDetails',
  SHOW_USER_DETAILS: 'showUserDetails',
  SHOW_DIALOG_DETAILS: 'showDialogDetails',
  SHOW_VIDEO_DETAILS: 'showVideoDetails',
  SHOW_MOMENT_DETAILS: 'showMomentDetails',
  SHOW_ADMIN_USER_DETAILS: 'showAdminUserDetails',
  SHOW_MAINTENANCE_DETAILS: 'showMaintenanceDetails',
  // REPORTS DETAILS
  SHOW_USER_REPORT_DETAILS: 'showUserReportDetails',
  SHOW_VIDEO_REPORT_DETAILS: 'showVideoReportDetails',
  SHOW_MOMENT_REPORT_DETAILS: 'showMomentReportDetails',
  SHOW_MESSAGE_REPORT_DETAILS: 'showMessageReportDetails',
  SHOW_CHAT_DETAILS: 'showChatMessageDetails',
  // GRIDS
  SHOW_MOMENT_GRID: 'showMomentGrid',
  SHOW_CHAT_GRID: 'showChatGrid',
  // GO BACK
  GO_BACK_TO_GRID: 'goBackToGrid',
}

export type CountryType = {
  code: string
  label: string
}

export const DEFAULT_COUNTRY: CountryType = {
  code: 'others',
  label: 'Others',
}
export const COUNTRIES: readonly CountryType[] = [
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'US',
    label: 'United States',
  },
  DEFAULT_COUNTRY,
]

export const TIMEZONES = [
  {
    name: 'America/Los_Angeles',
    short: 'PST',
    offset: -7,
  },
  {
    name: 'Asia/Tokyo',
    short: 'JST',
    offset: 9,
  },
  {
    name: 'UTC',
    short: 'UTC',
    offset: 0,
  },
]
