import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, IconButton, Typography } from '@mui/material'
import { lightBlue, orange, red } from '@mui/material/colors'
import {
  GridColumns, GridFilterModel, GridRowParams, GridSortItem, GridSortModel,
  DataGrid as MuiDataGrid, getGridDateOperators, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators,
} from '@mui/x-data-grid'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import eventBus from '../../services/eventBus'
import { fetchAdmin } from '../../services/fetch'
import { GridApiUrlQueries, UrlQueries } from '../../services/urlBuilder'
import { DateText } from '../app/Timezone'
import { QuickSearchToolbar } from './QuickSearchToolBar'

interface Props {
  show: boolean
  refresh: boolean
  path: string
  columns: GridColumns
  autoHeight?: boolean
  showDeletedCheckbox?: boolean
  showResolvedCheckbox?: boolean
  showTimeZoneSelect?: boolean
  searchColumns?: Array<{ label: string, field: string }>
  rowHeight?: number
  setCustomRowClassName?: (params: GridRowParams) => string // NOTE: for row color
  setCustomRowDescriptions?: Array<{
    rowClassName: string
    description: string
  }>
  onRowDoubleClick?: (params: GridRowParams) => void
  initialSortItem?: GridSortItem
  sortingMode?: 'client' | 'server'
  filterMode?: 'client' | 'server'
  disableToolbar?: boolean
  toolbarComponent?: React.JSXElementConstructor<any>
  initToolbarQueries?: UrlQueries
}

function DataGrid(props: Props) {
  const {
    autoHeight,
    rowHeight,
    onRowDoubleClick,
    sortingMode,
    filterMode,
    initialSortItem,
    searchColumns,
    showDeletedCheckbox,
    showResolvedCheckbox,
    setCustomRowClassName,
    setCustomRowDescriptions,
    showTimeZoneSelect,
    disableToolbar,
    toolbarComponent,
    initToolbarQueries,
  } = props

  const {
    show, refresh, path, columns,
  } = props
  const [pageState, setPageState] = useState({
    isLoading: false,
    rows: [],
    totalRows: 0,
    page: 0,
    pageSize: 25,
    sortModel: [initialSortItem ?? { field: 'createdAt', sort: 'desc' }] as GridSortModel,
    filterModel: { items: [] } as GridFilterModel,
  })

  const [searchColumn, setSearchColumn] = useState((searchColumns != null) ? searchColumns[0].field : 'id')
  const [showDeleted, setShowDeleted] = useState(true)
  const [showResolved, setShowResolved] = useState(false)
  const [toolbarQueries, setToolbarQueries] = useState(initToolbarQueries)

  function updatePageState(newState: object) {
    setPageState((old) => ({ ...old, ...newState }))
  }

  const fetchData = useCallback(async () => {
    if (sortingMode === 'client' && filterMode === 'client') {
      return
    }
    const queries: GridApiUrlQueries = {
      limit: pageState.pageSize,
      offset: pageState.page * pageState.pageSize,
      showDeleted,
      showResolved,
      ...toolbarQueries,
    }

    if (pageState.sortModel.length > 0) {
      queries.sortField = pageState.sortModel[0].field
      queries.dir = pageState.sortModel[0].sort
    }

    if (pageState.filterModel.items.length > 0 && pageState.filterModel.items[0].value !== undefined) {
      queries.filterField = pageState.filterModel.items[0].columnField
      queries.filterOp = pageState.filterModel.items[0].operatorValue
      queries.filterValue = pageState.filterModel.items[0].value
    }

    // NOTE: search function
    if ((pageState.filterModel.quickFilterValues != null) && pageState.filterModel.quickFilterValues.length > 0) {
      queries.searchOp = 'contains'
      queries.searchField = searchColumn
      queries.searchValues = pageState.filterModel.quickFilterValues.join(',')
    }

    try {
      updatePageState({ isLoading: true })

      const response = await fetchAdmin({
        path,
        method: 'GET',
        queries,
      })
      const json = await response.json()
      updatePageState({ rows: json.rows, totalRows: json.totalRows, isLoading: false })
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }, [pageState.page, pageState.pageSize, pageState.sortModel, pageState.filterModel, searchColumn, path, showDeleted, showResolved, sortingMode, filterMode, toolbarQueries])

  useEffect(() => {
    fetchData()
  }, [fetchData, refresh])

  return show
    ? (
      <DataGridBox>
        <MuiDataGrid
          columns={columns}
          rows={pageState.rows}
          rowCount={pageState.totalRows}
          rowHeight={rowHeight ?? 40}
          autoHeight={autoHeight ?? false}
          loading={pageState.isLoading}
          page={pageState.page}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(page) => updatePageState({ page })}
          onPageSizeChange={(pageSize) => updatePageState({ pageSize })}
          sortingMode={sortingMode ?? 'server'}
          sortModel={pageState.sortModel}
          onSortModelChange={(sortModel) => updatePageState({ sortModel })}
          filterMode={filterMode ?? 'server'}
          filterModel={pageState.filterModel}
          onFilterModelChange={(filterModel) => updatePageState({ filterModel })}
          onRowDoubleClick={onRowDoubleClick}
          components={disableToolbar ? undefined : { Toolbar: toolbarComponent ?? QuickSearchToolbar }} // NOTE: search function
          componentsProps={{ // NOTE: search function
            toolbar: {
              showDeleted,
              setShowDeleted,
              showResolvedCheckbox: showResolvedCheckbox ?? false,
              showDeletedCheckbox: showDeletedCheckbox ?? false,
              showResolved,
              setShowResolved,
              setSearchColumn,
              autoCompleteOptions: searchColumns ?? [{ label: 'ID', field: 'id' }],
              customRowDescriptions: setCustomRowDescriptions,
              showTimeZoneSelect,
              setToolbarQueries,
              toolbarQueries,
            },
          }}
          getRowClassName={setCustomRowClassName}
        />
      </DataGridBox>
    )
    : null
}

export default DataGrid

export function buildDetailsButton<T>({
  event,
  callback,
}: {
  event?: string
  callback?: (data: T) => void
}): React.FC<{ data: T }> {
  const button: React.FC<{ data: T }> = (props) => {
    const { data } = props

    function handleClick() {
      if (event) {
        eventBus.dispatch(event, data)
      } else if (callback != null) {
        callback(data)
      }
    }

    return (
      <IconButton onClick={handleClick}>
        <NavigateNextIcon />
      </IconButton>
    )
  }

  return button
}

export const filterStringOps = getGridStringOperators().filter(
  (op) => op.value !== 'isAnyOf',
)

export const filterNumericOps = getGridNumericOperators().filter(
  (op) => op.value === '=',
)

export const filterDateTimeOps = getGridDateOperators(true).filter(
  (op) => op.value === 'before' || op.value === 'after',
)

export const fliterSingleSelectOps = getGridSingleSelectOperators().filter(
  (op) => op.value === 'is',
)

export function DateCell(props: { date: string }) {
  const { date } = props

  return <Typography variant="body2"><DateText date={date} /></Typography>
}

interface DataGridBoxProps {
  children?: React.ReactNode
}

function DataGridBox(props: DataGridBoxProps) {
  const { children } = props

  return (
    <Box // custom role color
      sx={{
        height: '100%',
        width: '100%',
        '& .row-color-info': {
          bgcolor: lightBlue[50],
          '&:hover, &.Mui-hovered': {
            bgcolor: lightBlue[100],
          },
        },
        '& .row-color-disabled': {
          bgcolor: orange[50],
          '&:hover, &.Mui-hovered': {
            bgcolor: orange[100],
          },
        },
        '& .row-color-error': {
          bgcolor: red[50],
          '&:hover, &.Mui-hovered': {
            bgcolor: red[100],
          },
        },
      }}
    >
      {children}
    </Box>
  )
}
