import { Delete, NavigateBefore } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar, Box, Button, Card, CardContent, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import { COUNTRIES, CountryType, DEFAULT_COUNTRY } from '../../constants'
import { fetchAdmin } from '../../services/fetch'
import ConfirmModal from '../common/ConfirmModal'
import { DateCell } from '../common/DataGrid'
import { Tab, TabPanel, Tabs } from '../common/Tabs'
import WidgetTagsAuth, { expectedWidgetUpdateUserTag, expectedWidgetDeleteUserTag } from '../common/WidgetTagsAuth'
import ChangingRegionModal from './ChangingRegionModal'
import DeleteUserModal from './DeleteUserModal'
import SwitchingOfficialModal from './SwitchingOfficialModal'
import SwitchingStaffModal from './SwitchingStaffModal'
import UserMomentList from './UserMomentList'

interface Props {
  quickbloxUserId: number
  backText: string
  exitUserDetails: (deletedUser: boolean) => void
}

interface UserDetailsCardProps {
  details: UserDetailsInfo
  updateView: () => void
}

interface UserControlButtonsProps {
  details: UserDetailsInfo
  updateView: () => void
}

interface UserDetailsInfo {
  quickbloxUserId: number
  userName: string
  fullName: string
  phone: string
  avatarUrl: string
  bioAudioPath: string
  isOfficial: boolean
  isStaff: boolean
  region: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
  dialogs: Dialog[]
}

interface Dialog {
  quickbloxDialogId: string
  type: string
  name: string | null
}

interface DialogCardProps {
  dialogs: Dialog[]
}

const dialogColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
  },
  {
    field: 'quickbloxDialogId',
    headerName: 'QB Dialog ID',
    width: 300,
  },
]

function UserDetails(props: Props) {
  const { quickbloxUserId, backText, exitUserDetails } = props
  const [details, setDetails] = useState<UserDetailsInfo>()
  const updateView = useCallback(() => {
    fetchUser({
      quickbloxUserId,
      setDetails,
    })
  }, [quickbloxUserId])

  useEffect(() => {
    updateView()
  }, [updateView])

  return (
    <>
      {
        backText
          && (
            <Button
              startIcon={<NavigateBefore />}
              onClick={() => exitUserDetails(false)}
            >
              {backText}
            </Button>
          )
      }

      <Tabs>
        <Tab label="details" />
        <Tab label="moments" />
        <Tab label="dialogs" />
      </Tabs>

      <TabPanel>
        {
          details !== undefined
            && (
              <UserDetailsCard
                details={details}
                updateView={updateView}
              />
            )
        }

      </TabPanel>

      <TabPanel>
        <UserMomentList
          quickbloxUserId={quickbloxUserId}
        />
      </TabPanel>

      <TabPanel>
        {
          details !== undefined
            && (
              <DialogsCard
                dialogs={details.dialogs}
              />
            )
        }
      </TabPanel>
    </>
  )
}

function UserDetailsCard(props: UserDetailsCardProps) {
  const { details, updateView } = props

  return (
    <>
      <Stack direction="row">
        <Avatar
          src={details.avatarUrl}
          sx={{ width: 100, height: 100, m: 2 }}
        >
          <Typography variant="h4">
            {details.userName ? details.userName.charAt(0) : '?'}
          </Typography>
        </Avatar>

        <Stack direction="column" justifyContent="center">
          <Typography variant="h4" m={0}>
            {details.fullName}
          </Typography>
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {`@${details.userName}`}
          </Typography>
        </Stack>
      </Stack>

      <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag || expectedWidgetDeleteUserTag}>
        <UserControlButtons
          details={details}
          updateView={updateView}
        />
      </WidgetTagsAuth>

      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Quickblox User ID
                  </TableCell>
                  <TableCell>
                    {details.quickbloxUserId}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Number of Dialogs
                  </TableCell>
                  <TableCell>
                    {details.dialogs.length}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Phone
                  </TableCell>
                  <TableCell>
                    {details.phone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    AvatarUrl
                  </TableCell>
                  <TableCell>
                    {details.avatarUrl}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    BioAudioPath
                  </TableCell>
                  <TableCell>
                    {details.bioAudioPath}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    CreatedAt
                  </TableCell>
                  <TableCell>
                    <DateCell date={details.createdAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    UpdatedAt
                  </TableCell>
                  <TableCell>
                    <DateCell date={details.updatedAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    DeletedAt
                  </TableCell>
                  <TableCell>
                    <DateCell date={details.deletedAt} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}

function UserControlButtons(props: UserControlButtonsProps) {
  const { details, updateView } = props
  const [showRegionModal, setShowRegionModal] = useState(false)
  const [newRegion, setNewRegion] = useState(DEFAULT_COUNTRY)
  const [showOfficialModal, setShowOfficialModal] = useState(false)
  const [showStaffModal, setShowStaffModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showBanModal, setShowBanModal] = useState(false)

  function handleOpenRegionModal(region: CountryType) {
    setNewRegion(region)
    setShowRegionModal(true)
  }

  function handleCloseRegionModal(actionTriggered: boolean, region?: string) {
    setShowRegionModal(false)

    if (actionTriggered && region !== undefined) {
      updateView()
    }
  }

  function handelOpenOfficialModal() {
    setShowOfficialModal(true)
  }

  function handleCloseOfficialModal(actionTriggered: boolean, isOfficial?: boolean) {
    setShowOfficialModal(false)

    if (actionTriggered && isOfficial !== undefined) {
      updateView()
    }
  }

  function handleOpenStaffModal() {
    setShowStaffModal(true)
  }

  function handleCloseStaffModal(actionTriggered: boolean, isStaff?: boolean) {
    setShowStaffModal(false)

    if (actionTriggered && isStaff !== undefined) {
      updateView()
    }
  }

  function handleOpenDeleteModal() {
    setShowDeleteModal(true)
  }

  function handleCloseDeleteModal(actionTriggered: boolean) {
    setShowDeleteModal(false)

    if (actionTriggered) {
      updateView()
    }
  }

  function handleOpenBanModal() {
    setShowBanModal((show) => !show)
  }

  function handleBanUser() {
    fetchAdmin({
      path: `users/${details.quickbloxUserId}/ban`,
      method: 'PUT',
    })
      .then(() => { updateView() })
  }

  return (
    <>
      <Stack direction="row" spacing={2} m={1}>
        <Autocomplete
          sx={{ width: 170 }}
          disableClearable
          options={COUNTRIES}
          value={details.region ? COUNTRIES.find((c) => c.code === details.region) : DEFAULT_COUNTRY}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              handleOpenRegionModal(newValue)
            }
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(params, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
              {
                option.code !== 'others'
                  && (
                    <img
                      alt={option.label}
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    />
                  )
              }
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // NOTE: disable autocomplete and autofill by any browsers suggestion
              }}
            />
          )}
        />
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag}>
          <Button
            onClick={handelOpenOfficialModal}
            variant={details.isOfficial ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/officialBadge.png`}
              />
          )}
          >
            Official
          </Button>

          <Button
            onClick={handleOpenStaffModal}
            variant={details.isStaff ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/staffBadge.png`}
              />
          )}
          >
            Staff
          </Button>

          <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteUserTag}>
            {
          details.isDeleted
            ? (
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
              >
                Deleted already
              </Button>
            )
            : (
              <Button
                onClick={handleOpenDeleteModal}
                variant="outlined"
                color="error"
                startIcon={<Delete />}
              >
                Delete
              </Button>
            )
        }
          </WidgetTagsAuth>
          <Button
            variant={details.isDeleted ? 'contained' : 'outlined'}
            color="error"
            startIcon={<Delete />}
            onClick={details.isDeleted ? undefined : handleOpenBanModal}
          >
            BAN
          </Button>
        </WidgetTagsAuth>
      </Stack>

      <ChangingRegionModal
        userId={details.quickbloxUserId}
        name={details.userName}
        oldCountry={COUNTRIES.find((c) => c.code === details.region) || DEFAULT_COUNTRY}
        newCountry={newRegion}
        open={showRegionModal}
        handleClose={handleCloseRegionModal}
      />

      <SwitchingOfficialModal
        userId={details.quickbloxUserId}
        name={details.userName}
        isOfficial={details.isOfficial}
        open={showOfficialModal}
        handleClose={handleCloseOfficialModal}
      />

      <SwitchingStaffModal
        userId={details.quickbloxUserId}
        name={details.userName}
        isStaff={details.isStaff}
        open={showStaffModal}
        handleClose={handleCloseStaffModal}
      />

      <DeleteUserModal
        userId={details.quickbloxUserId}
        name={details.userName}
        open={showDeleteModal}
        handleClose={handleCloseDeleteModal}
      />

      <ConfirmModal
        openTrigger={showBanModal}
        title={`BAN the user "${details.userName}"?`}
        message="Once BAN, you can&apos;t undo this operation."
        onAccept={handleBanUser}
      />
    </>
  )
}

function DialogsCard(props: DialogCardProps) {
  const { dialogs } = props

  return (
    <Card>
      <CardContent>
        <DataGrid
          columns={dialogColumns}
          rows={dialogs}
          getRowId={(row) => row.quickbloxDialogId}
          autoHeight
        />
      </CardContent>
    </Card>
  )
}

function fetchUser({
  quickbloxUserId,
  setDetails,
}: {
  quickbloxUserId: number
  setDetails: (details: UserDetailsInfo) => void
}): void {
  fetchAdmin({
    path: `users/${quickbloxUserId}`,
    method: 'GET',
  })
    .then((res) => res.json())
    .then((json) => {
      const { user, dialogs } = json

      setDetails({
        ...user,
        dialogs,
        quickbloxUserId,
        avatarUrl: user.customData.avatarUrl,
        isDeleted: !!user.deletedAt,
      })
    })
}

export default UserDetails
