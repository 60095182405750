import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material'
import { DateTime } from 'luxon'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { TIMEZONES } from '../../constants'
import { getStorage, setStorage } from '../../services/storage'

interface TimezoneContextType {
  timezone: string
  changeTimezone: (tz: string) => void
}
interface TimezoneProviderProps {
  children?: ReactNode
}

export const TimezoneContext = createContext<TimezoneContextType>({} as TimezoneContextType)
const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}))

export function TimezoneProvider(props: TimezoneProviderProps) {
  const { children } = props
  const [timezone, setTimezone] = useState(getStorage('timezone', TIMEZONES[0].name))
  const changeTimezone = useCallback((tz: string) => {
    setStorage('timezone', tz)
    setTimezone(tz)
  }, [])
  const providerValue = useMemo(() => ({
    timezone,
    changeTimezone,
  }), [timezone, changeTimezone])

  return (
    <TimezoneContext.Provider value={providerValue}>
      {children}
    </TimezoneContext.Provider>
  )
}

export function SelectTimeZone() {
  const { timezone, changeTimezone } = useContext(TimezoneContext)
  const onChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    if (typeof event.target.value === 'string') {
      changeTimezone(event.target.value)
    }
  }

  return (
    <FormControl>
      <StyledSelect
        value={timezone}
        size="small"
        onChange={onChange}
      >
        <ListSubheader>timezone</ListSubheader>
        {TIMEZONES.map((tz) => (<MenuItem key={`menuitem-${tz.short}`} value={tz.name}>{tz.short}</MenuItem>))}
      </StyledSelect>
    </FormControl>
  )
}

export function DateText(props: { date?: string | number }) {
  const { date } = props
  const { timezone } = useContext(TimezoneContext)
  let datetime: DateTime | undefined

  if (date) {
    if (typeof date === 'number' || Number.isFinite(date)) {
      datetime = DateTime.fromMillis(
        typeof date === 'number' ? date : Number.parseInt(date),
        { zone: timezone },
      )
    } else {
      datetime = DateTime.fromISO(date, { zone: timezone })
    }
  }

  return datetime && datetime.isValid
    ? <>{datetime.toFormat('yyyy/MM/dd HH:mm:ss')}</>
    : null
}
