import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import { buildMomentThumbnailUrl, buildMomentVideoUrl } from '../../services/urlBuilder'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterNumericOps,
} from '../common/DataGrid'
import VideoCard from '../common/VideoCard'

interface Props {
  show: boolean
  refresh: boolean
}

function MomentGrid(props: Props) {
  const { show, refresh } = props

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="moments"
      columns={columns}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_MOMENT_DETAILS, row.row)}
      showDeletedCheckbox
      searchColumns={[
        { label: 'ID', field: 'id' },
        { label: 'User ID', field: 'quickbloxUserId' },
        { label: 'Identifier', field: 'identifier' },
        { label: 'Comment', field: 'commentNum' },
      ]}
      setCustomRowClassName={(params) => {
        if (params.row.deletedAt) return 'row-color-error'
        return ''
      }}
      setCustomRowDescriptions={[
        { rowClassName: 'row-color-error', description: 'Deleted' },
      ]}
      rowHeight={190}
      showTimeZoneSelect
    />
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_MOMENT_DETAILS })

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    filterOperators: filterNumericOps,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    filterOperators: filterNumericOps,
  },
  {
    field: 'contents',
    headerName: 'Thumbnail',
    type: 'boolean',
    width: 100,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <VideoCard
        thumbnailUrl={buildMomentThumbnailUrl(params.row?.contents?.top?.thumbnailPublicId)}
        videoUrl={buildMomentVideoUrl(params.row?.contents?.top?.thumbnailPublicId)}
      />
    ),
  },
  {
    field: 'commentNum',
    headerName: 'Comment',
    width: 100,
  },
  {
    field: 'likeNum',
    headerName: 'Like',
    width: 60,
  },
  {
    field: 'identifier',
    headerName: 'Identifier',
    width: 200,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'deletedAt',
    headerName: 'Deleted At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default MomentGrid
