import { useState, useEffect } from 'react'

import UserDetails from './UserDetails'
import eventBus from '../../services/eventBus'
import UserGrid from './UserGrid'
import { EVENT } from '../../constants'

function Users() {
  const [curQuickbloxUserId, setCurQuickbloxUserId] = useState(0) // If curId is not 0, show the corresponding user details. Otherwise, show the grid.
  const [refresh, setRefresh] = useState(false) // This state is toggled to rerender the user grid.

  const handleExitUserDetails = () => {
    setCurQuickbloxUserId(0)
    setRefresh((r) => !r)
  }

  useEffect(() => {
    function showUserHelper(e: CustomEvent) {
      setCurQuickbloxUserId(e.detail.quickbloxUserId)
    }
    eventBus.on(EVENT.SHOW_USER_DETAILS, showUserHelper)

    return () => {
      eventBus.remove('showUserDetails', showUserHelper)
    }
  }, [])

  return (
    <>
      <UserGrid show={!curQuickbloxUserId} refresh={refresh} />
      { curQuickbloxUserId !== 0
      && <UserDetails quickbloxUserId={curQuickbloxUserId} backText="Users" exitUserDetails={handleExitUserDetails} />}
    </>
  )
}

export default Users
