import {
  Box,
  Button,
  CardActions,
  Stack,
  TextField,
  Typography,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
} from '@mui/material'
import {
  DataGrid,
  GridColumnGroupingModel,
  GridColumns,
  GridEnrichedColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useState } from 'react'
import { TIMEZONES } from '../../constants'
import { fetchAdmin } from '../../services/fetch'
import { numberFormat } from '../../services/number'

type JSONPrimitive = string | number | boolean | null | undefined; type JSONValue = JSONPrimitive | JSONValue[] | { [key: string]: JSONValue; }; type NotAssignableToJson = | bigint | symbol | Function; type JSONCompatible<T> = unknown extends T ? never : { [P in keyof T]: T[P] extends JSONValue ? T[P] : T[P] extends NotAssignableToJson ? never : JSONCompatible<T[P]>; }; function toJsonValue<T>(value: JSONCompatible<T>): JSONValue { return value; } function safeJsonStringify<T>(data: JSONCompatible<T>) { return JSON.stringify(data); } function safeJsonParse(text: string): unknown { return JSON.parse(text); }

interface FormData {
  from: moment.Moment
  to: moment.Moment
  timezone: string
  storeCountryCode: string
}

interface CustomColumnData {
  name: string[]
  description: string
  field: string | string[]
}

const initColumns: GridColumns = [
  {
    sortable: false,
    field: 'id',
  },
  {
    sortable: false,
    field: 'ymd',
    align: 'center',
    valueGetter: ({ value }) => moment(value.toString()).format('YYYY/MM/DD'),
  },
  {
    sortable: false,
    field: 'days',
    align: 'center',
    width: 60,
    valueGetter: ({ row }) => moment(row.ymd.toString()).format('ddd'),
    renderCell: ({ row }) => {
      const days = moment(row.ymd.toString())
      const isWeekEnd = parseInt(days.format('E')) >= 6

      return (
        <Typography variant="body2" color={isWeekEnd ? 'red' : undefined}>
          {days.format('ddd')}
        </Typography>
      )
    },
  },

  // User
  {
    sortable: false,
    field: 'user_total',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['user total']}
        description="電話番号登録済みの合計ユーザー数"
      />
    ),
  },
  {
    sortable: false,
    field: 'firstOpen_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['first open']}
        description="インストール後はじめてアプリを開いた人数"
      />
    ),
    renderCell: (params: any) => {
      const storeCountryCode = params.row.firstOpen_storeCountryCode
      return (
        <Tooltip title={storeCountryCode}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
  },
]

const initStatus = {
  columns: [],
  rows: [],
  loading: false,
}

const initFormData = {
  from: moment().subtract(14, 'day'),
  to: moment().subtract(1, 'day'),
  timezone: TIMEZONES[0].name,
  storeCountryCode: 'USA_CAN',
}

function KpiDBCustom() {
  const [status, setStatus] = useState(initStatus)
  const updateStatus = (newStatus: object) => {
    setStatus((old) => ({ ...old, ...newStatus }))
  }

  return (
    <DataGrid
      loading={status.loading}
      columns={status.columns}
      rows={status.rows}
      components={{
        Toolbar: SearchForm,
      }}
      componentsProps={{
        toolbar: {
          updateStatus,
        },
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      disableColumnFilter
      disableColumnMenu
      hideFooter
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          overflowWrap: 'break-word',
          wordBreak: 'break-all',
          lineHeight: '1rem',
          whiteSpace: 'normal',
        },
        '.headerBorder': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
          // borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderLeft': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderRight': {
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.bgDays': {
          backgroundColor: '#7fffff',
        },
        '.bgDaysPpl': {
          backgroundColor: '#7fbfff',
        },
        '.bgDaysCnt': {
          backgroundColor: '#7f7fff',
        },
        '.bgDaysAfter': {
          backgroundColor: '#7fff7f',
        },
        '.bgDaysAfterPpl': {
          backgroundColor: '#bfff7f',
        },
        '.bgDaysAfterCnt': {
          backgroundColor: '#7fffbf',
        },
      }}
      showColumnRightBorder
      showCellRightBorder
    />
  )
}

function SearchForm(props: { updateStatus: (obj: object) => void }) {
  const { updateStatus } = props
  const [formData, setFormData] = useState<FormData>(initFormData)
  const updateFormData = (newFormData: object) => {
    setFormData((old) => ({ ...old, ...newFormData }))
  }

  const [columnData, setColumnData] = useState<string>('[]')
  const updateColumnData = (newColumnData: string) => {
    setColumnData(newColumnData)
  }

  const onSubmit = async () => {
    updateStatus({ loading: true })

    const { columns, rows } = await fetchData({ formData, columnData })

    updateStatus({
      columns,
      rows,
      loading: false,
    })
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box padding={1}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="from"
              value={formData.from}
              onChange={(value) => updateFormData({ from: value })}
              renderInput={(params) => <TextField {...params} sx={{ maxWidth: 170 }} />}
              inputFormat="YYYY/MM/DD"
            />

            <DatePicker
              label="to"
              value={formData.to}
              onChange={(value) => updateFormData({ to: value })}
              renderInput={(params) => <TextField {...params} sx={{ maxWidth: 170 }} />}
              inputFormat="YYYY/MM/DD"
            />

            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel id="kpi-country-select-label">
                Country
              </InputLabel>
              <Select
                labelId="kpi-country-select-label"
                id="kpi-country-select"
                value={formData.storeCountryCode}
                label="Country"
                onChange={(event) => updateFormData({ storeCountryCode: event.target.value })}
              >
                <MenuItem value="">ALL</MenuItem>
                <MenuItem value="USA_CAN">USA & CAN</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="CAN">CAN</MenuItem>
                <MenuItem value="JPN">JPN</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="kpi-timezone-select-label">
                timezone
              </InputLabel>
              <Select
                labelId="kpi-timezone-select-label"
                id="kpi-timezone-select"
                value={formData.timezone}
                label="timezone"
                onChange={(event) => updateFormData({ timezone: event.target.value })}
              >
                <MenuItem value="UTC">UTC</MenuItem>
                <MenuItem value="America/Los_Angeles">PT</MenuItem>
                <MenuItem value="Asia/Tokyo">JST</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
              <TextareaAutosize
                aria-label="customCollumns"
                minRows={2}
                maxRows={10}
                onChange={(event) => updateColumnData(event.target.value)}
              />
            </FormControl>
          </Stack>

          <CardActions>
            <Button
              variant="outlined"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </CardActions>

          <GridToolbarContainer>
            <GridToolbarExport
              csvOptions={{
                fileName: 'kpi',
              }}
              printOptions={{
                disableToolbarButton: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </LocalizationProvider>
    </>
  )
}

function Cell(props: { value: number | { [key: string]: number } }) {
  const { value } = props

  if (typeof value === 'object') {
    const { countUser, countEvent } = value

    return (
      <Typography variant="body2" align="right">
        {countUser}
        <Typography variant="caption"> ppl</Typography>
        <br />
        {countEvent}
        <Typography variant="caption"> cnt</Typography>
      </Typography>
    )
  }

  return (
    <Typography variant="body2">
      {(value || value === 0) ? numberFormat(value) : '0'}
    </Typography>
  )
}

async function fetchData({
  formData,
  columnData,
}: {
  formData: FormData
  columnData: string
}) {
  const customColumns: CustomColumnData[] = JSON.parse(columnData)
  const columns: GridColumns = []

  formData.from.toJSON = () => formData.from.format('YYYYMMDD')
  formData.to.toJSON = () => formData.to.format('YYYYMMDD')

  const rows = await fetchAdmin({
    path: 'analytics/kpi/db',
    method: 'GET',
    queries: {
      form: JSON.stringify(formData),
    },
  })
    .then((res) => res.json())
    .catch(() => {})

  initColumns.forEach((col) => {
    columns.push(initGridColumn(col))
  })

  customColumns.forEach((customCol) => {
    const initCol = {
      sortable: false,
      headerClassName: 'borderLeft',
      cellClassName: 'borderLeft',
      renderHeader: () => (
        <MultilineHeader
          names={customCol.name}
          description={customCol.description}
        />
      ),
    }
    if (typeof customCol.field === 'string') {
      const col: GridEnrichedColDef = {
        ...initCol,
        field: customCol.field,
      }
      columns.push(initGridColumn(col))
    }
    if (Array.isArray(customCol.field)) {
      const col: GridEnrichedColDef = {
        ...initCol,
        field: customCol.field.join('_'),
        renderCell: (params: any) => {
          // NOTE: Percentage Calculation
          const a = parseInt(params.row[customCol.field[0]])
          const b = parseInt(params.row[customCol.field[1]])
          const result = (b / a) * 100
          return `${Math.round(result)}%`
        },
      }
      columns.push(initGridColumn(col))
    }
  })

  return {
    columns,
    rows,
  }
}

function initGridColumn(column: GridEnrichedColDef): GridEnrichedColDef {
  return {
    type: 'number',
    headerAlign: 'center',
    renderCell: column.valueGetter
      ? undefined
      : ({ value }) => <Cell value={value} />,
    hideSortIcons: true,
    ...column,
  }
}

function MultilineHeader(props: { names: string[], description: string }) {
  const { names, description } = props
  let lineNo = 0

  return (
    <Tooltip title={description}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ lineHeight: '1rem' }}
      >
        {
          names.map((name) => (
            <Grid item key={++lineNo}>{name}</Grid>
          ))
        }
      </Grid>
    </Tooltip>
  )
}

export default KpiDBCustom
