import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, COUNTRIES, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton, filterDateTimeOps, filterNumericOps, filterStringOps, fliterSingleSelectOps,
} from '../common/DataGrid'

interface Props {
  show: boolean
  refresh: boolean
}

function UserGrid(props: Props) {
  const { show, refresh } = props
  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="users"
      columns={columns}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_USER_DETAILS, row.row)}
      showDeletedCheckbox
      searchColumns={[
        { label: 'UserId', field: 'quickbloxUserId' },
        { label: 'UserName', field: 'userName' },
        { label: 'FullName', field: 'fullName' },
        { label: 'Phone', field: 'phone' },
        { label: 'Region', field: 'region' },
      ]}
      setCustomRowClassName={(params) => {
        if (params.row.deletedAt) return 'row-color-error'
        return ''
      }}
      setCustomRowDescriptions={[
        { rowClassName: 'row-color-error', description: 'Deleted' },
      ]}
      showTimeZoneSelect
    />
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_USER_DETAILS })

const columns: GridColumns = [
  {
    field: 'quickbloxUserId',
    headerName: 'UserId',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    filterOperators: filterNumericOps,
  },
  {
    field: 'userName',
    headerName: 'UserName',
    width: 150,
    filterOperators: filterStringOps,
  },
  {
    field: 'fullName',
    headerName: 'FullName',
    width: 150,
    filterOperators: filterStringOps,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 130,
    align: 'right',
  },
  {
    field: 'region',
    headerName: 'Region',
    type: 'singleSelect',
    valueOptions: (COUNTRIES.map((country) => ({
      value: country.code,
      label: country.code,
    }))),
    width: 150,
    filterOperators: fliterSingleSelectOps,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'deletedAt',
    headerName: 'Deleted At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default UserGrid
